import React from "react";
import CalendarView from "../components/Calendar/CalendarView";

const Calendar = () => {
    return (
        <div className="container">
            <h2>KALENDARZ - SERWIS</h2>
            <CalendarView link={"service"} />
            <h2>KALENDARZ - PRODUKCJA</h2>
            <CalendarView link={"production"} />
        </div>
    );
};

export default Calendar;
