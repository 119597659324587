import React from "react";

const Table = props => {
  const fence = props.fence;
  const name = fence.name;
  const type = fence.type;
  const fenceHeight = fence.data.fenceHeight * 10;
  const fenceLength = fence.data.fenceLength * 10;
  const lamelSpace = fence.data.lamelSpace * 10;
  const lamelHeight = fence.data.lamelHeight * 10;
  const lamelCount = fence.data.totalLamelCount;
  const fenceAmount = fence.amount;

  const lamelSpaceDisplay = () => {
    return lamelSpace ? null : { display: "none" };
  };

  const lamelHeightDisplay = () => {
    return lamelHeight ? null : { display: "none" };
  };

  const lamelCountDisplay = () => {
    return lamelCount ? null : { display: "none" };
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-bordered table-sm mx-auto align-top"
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          width: "20%"
        }}
      >
        <thead>
          <tr>
            <th scope="col">Nazwa</th>
            <th scope="col">Typ</th>
            <th scope="col">Wys.</th>
            <th scope="col">Szer.</th>
            <th scope="col" style={lamelSpaceDisplay()}>
              Rozstaw lameli
            </th>
            <th scope="col" style={lamelHeightDisplay()}>
              Wys. lameli
            </th>
            <th scope="col" style={lamelCountDisplay()}>
              Ilość lameli
            </th>
            <th scope="col" style={{ backgroundColor: "#C5D6E0" }}>
              Ilość
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{name}</td>
            <td>{type}</td>
            <td>{fenceHeight}</td>
            <td>{fenceLength}</td>
            <td style={lamelSpaceDisplay()}>{lamelSpace.toFixed(2)}</td>
            <td style={lamelHeightDisplay()}>{lamelHeight}</td>
            <td style={lamelCountDisplay()}>{lamelCount}</td>
            <td
              style={
                fenceAmount === 0
                  ? { backgroundColor: "red" }
                  : { backgroundColor: "#C5D6E0" }
              }
            >
              {fenceAmount}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
