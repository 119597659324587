import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Slider } from "react-burgers";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showText, setShowText] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    if (showText) {
      setShowText(!showText);
    } else {
      setTimeout(() => {
        setShowText(!showText);
      }, 250);
    }
  };

  return (
    <div className="sidebar">
      <div className={isOpen ? "sidebar-wide" : "sidebar-narrow"}>
        <Slider onClick={toggle} width={40} active={isOpen} color="#fff" />
        <NavLink exact={true} className="" activeClassName="active" to="/">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-home" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Panel</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/oferty">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-handshake-o" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Oferty</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/zamowienia">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-shopping-cart" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Zamówienia</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/raporty">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-file-text-o" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Raporty</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/uzytkownicy">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-user" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Użytkownicy</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/generator">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-align-justify" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Generator</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/cennik">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-money" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Cennik</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/checklista">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-list-alt" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Checklista</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/produkcja">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-cogs" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Produkcja</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/ksiegowosc">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-book" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Księgowość</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/statystyki">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-bar-chart" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Statystyki</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink className="" activeClassName="active" to="/kalendarz">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-calendar" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Kalendarz</p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink activeClassName="active" to="/pdf">
          <div className="sidebar-link">
            <div className="sidebar-box">
              <div className="sidebar-icon">
                <i className="fa fa-file-pdf-o" />
              </div>
              <div className={showText ? "sidebar-text" : "d-none"}>
                <p>Dokumenty</p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
