import React from "react";
import CalendarConfig from "./CalendarConfig";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pl";
import "moment/locale/en-gb";

const localizer = momentLocalizer(moment);

const CalendarLocalizer = props => {
    return <CalendarConfig localizer={localizer} link={props.link} />;
};

export default CalendarLocalizer;
