import React, { useContext, useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Context from "./context";
import reducer from "./reducer";

import Homepage from "./pages/Homepage";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Calendar from "./pages/Calendar";
import PdfView from "./pages/PdfView";
import Generator from "./components/Generator/Generator";

function App() {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <Navbar />
        <Sidebar />
        <Switch>
          <Route path="/" exact render={render => <Homepage {...render} />} />
          <Route path="/kalendarz" component={Calendar} />
          <Route path="/pdf" component={PdfView} />
          <Route exact path="/generator/:id" component={Generator} />
        </Switch>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
