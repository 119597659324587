import React from "react";
import Table from "./Table";
import uuid from "uuidv4";
import handle from "../../assets/images/handle.png";
import "../../assets/styles/ImagesList.scss";

const ImagesList = props => {
  return props.fenceData.map((fence, id) => {
    const fenceAmount = fence.amount === 0;
    const fenceHeight = `${fence.data.fenceHeight}px`;
    const post = {
      width: `${fence.data.fencePostLength}px`,
      border: "1px solid black",
      backgroundColor: fenceAmount ? "#CCC" : "#C5D6E0",
      height: fenceHeight
    };
    const lamel = {
      width: `${fence.data.lamelLength}px`,
      border: "1px solid black",
      backgroundColor: fenceAmount ? "#CCC" : "#C5D6E0",
      height: `${fence.data.lamelHeight}px`,
      index: fence.id
    };
    const lamelSpace = {
      width: `${fence.data.lamelLength}px`,
      backgroundColor: "#FFF",
      height: `${fence.data.lamelSpace}px`,
      index: fence.id
    };

    let newPostList = [];
    let newPostList2 = [];
    let newPostList3 = [];

    for (let i = 0; i < 1; i++) {
      newPostList.push(<div style={post} key={uuid()} />);
      if (`${fence.totalPostCount}` === "2") {
        newPostList3.push(<div style={post} key={uuid()} />);
      }
      if (`${fence.totalPostCount}` === "4") {
        newPostList2.push(<div style={post} key={uuid()} />);
        newPostList3.push(<div style={post} key={uuid()} />);
      }
    }

    let newDivList = [];
    let newDivList2 = [];

    for (let i = 0; i < `${fence.data.totalLamelCount}`; i++) {
      newDivList.push(
        [<div style={lamel} key={uuid()} />],
        [<div style={lamelSpace} key={uuid()} />]
      );

      if (`${fence.totalPostCount}` === "4") {
        newDivList2.push(
          [<div style={lamel} key={uuid()} />],
          [<div style={lamelSpace} key={uuid()} />]
        );
      }
    }

    newDivList.pop();
    newDivList2.pop();

    const disabled = () => {
      return true ? fenceAmount : false;
    };

    const addClassHandler = () => {
      return fence.name === "slup" ? "post-data" : null;
    };

    const styleHandleIcon = () => {
      return fence.name === "furtka"
        ? {
            display: "block",
            marginTop:
              fence.name === "furtka" && fenceHeight < "150px"
                ? "-80px"
                : "-100px"
          }
        : { display: "none" };
    };

    return (
      <div
        className="fences"
        style={{
          display: "inline-flex",
          flexDirection: "column",
          margin: "10px"
        }}
        key={uuid()}
      >
        <div className="tableComponent">
          <Table fence={fence} />
        </div>

        <div className="fence-container" key={fence.id}>
          <div className="fence-config">
            <div
              className="fence-content"
              id={uuid()}
              index={id}
              name={fence.name}
              width={fence.data.fenceLength}
              onClick={e => !disabled() && props.addItemHandler(id, e)}
            >
              <p
                style={{ height: "5px", fontWeight: 600, fontSize: "15px" }}
                className={addClassHandler()}
              >
                {fence.name}
              </p>
              <p
                style={{ height: "8px", fontWeight: 600, fontSize: "15px" }}
                className={addClassHandler()}
              >
                szer: {fence.data.fenceLength * 10}
              </p>
              <div className="fence-ready">
                <div>
                  {newPostList}
                  <img
                    alt="handleIconImage"
                    className="handleIcon"
                    src={handle}
                    style={styleHandleIcon()}
                  ></img>
                </div>
                <div>{newDivList}</div>
                <div>{newPostList2}</div>
                <div>{newPostList2}</div>
                <div>{newDivList2}</div>
                <div>{newPostList3}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default ImagesList;
