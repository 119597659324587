import React from "react";
import biuro1 from "../../assets/images/biuro1.jpg";
import biuro2 from "../../assets/images/biuro2.jpg";
import biuro3 from "../../assets/images/biuro3.jpg";

export default function About() {
    return (
        <div className="news">
            <h2 className="my-4">O nas</h2>
            <div className="row">
                <div className="col-md">
                    <div className="card">
                        <img
                            style={{ height: "200px", objectFit: "fill" }}
                            className="w-100 card-img-top"
                            src={biuro1}
                            alt=""
                        />

                        <div className="card-body">
                            <h5 className="card-title">
                                Ponad 40 lat na polskim rynku!
                            </h5>
                            <p className="card-text">
                                Z powodzeniem od 1975 roku zaopatrujemy miasto
                                Wrocław, jego okolice i cały Dolny Śląsk w
                                solidne, estetyczne, a przede wszystkim
                                niezawodne ogrodzenia. Zajmujemy się produkcją,
                                dystrybucją oraz montażem nowoczesnych systemów
                                ogrodzeniowych. Nasza marka gwarantuje
                                solidność, trwałość i wysoką estetykę
                                realizowanych zleceń.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="card">
                        <img
                            style={{ height: "200px", objectFit: "fill" }}
                            className="w-100 card-img-top"
                            src={biuro2}
                            alt=""
                        />

                        <div className="card-body">
                            <h5 className="card-title">
                                Kompleksowa realizacja od A do Z
                            </h5>
                            <p className="card-text">
                                Nasza oferta ma charakter kompleksowy, gdyż
                                obejmuje dostawę wybranego, kompletnego systemu
                                oraz ich profesjonalną instalację w oparciu o
                                wiedzę i umiejętności naszych doświadczonych
                                fachowców. Wykonujemy także nowoczesne
                                ogrodzenia oraz bramy według niestandardowych,
                                indywidualnych projektów i zleceń.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="card">
                        <img
                            style={{ height: "200px", objectFit: "fill" }}
                            className="w-100 card-img-top"
                            src={biuro3}
                            alt=""
                        />

                        <div className="card-body">
                            <h5 className="card-title">
                                Oferujemy montaż orazbezpłatną wycenę
                            </h5>
                            <p className="card-text">
                                Oferujemy darmową wycenę, profesjonalny montaż,
                                posiadamy własny transport, obsługujemy całą
                                Polskę. Oferowane przez nas nowoczesne
                                ogrodzenia od lat cieszą się dużym uznaniem
                                Klientów. Stała współpraca z najlepszymi
                                dostawcami dodaje wartości naszym produktom,
                                które spełniają rygorystyczne normy jakości.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
