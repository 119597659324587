import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdf1 from "../assets/doc/ogrodzenie_panelowe_proste.pdf";
import pdf2 from "../assets/doc/ogrodzenie_panelowe_przetlaczane.pdf";
import pdf3 from "../assets/doc/podmurowka.pdf";
import pdf4 from "../assets/doc/przeslony-dla-systemu-nylofor.pdf";
import "../assets/styles/PdfView.scss";
// import img3 from "../../assets/images/podmorowka.png";
// import img1 from "../../assets/images/panelowe-proste.png";
// import img4 from "../../assets/images/przeslona.jpg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
    pdfjs.version
}/pdf.worker.js`;

export default function PdfView() {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState();
    const [pdfActive, setPdfActive] = useState(pdf1);
    const onDocumentLoadSuccess = numPages => {
        setNumPages(numPages);
    };

    //this function get page number of file
    const loadingTask = pdfjs.getDocument(pdfActive);
    loadingTask.promise.then(res => {
        const numPages = res.numPages;
        setNumPages(numPages);
    });

    const setPdf = pdf => {
        setPdfActive(pdf);
        setPageNumber(1);
    };
    const pageLeft = () => {
        setPageNumber(pageNumber > 1 ? pageNumber - 1 : numPages);
    };
    const pageRight = () => {
        setPageNumber(pageNumber === numPages ? 1 : pageNumber + 1);
    };
    return (
        <div className="news mb-4 container">
            <h2 className="my-4">Dokumenty pdf</h2>
            <div className="row">
                <div className="col-md-4">
                    <div className="list-group" id="list-tab" role="tablist">
                        <a
                            className="list-group-item list-group-item-action active"
                            id="list-home-list"
                            data-toggle="list"
                            href="#list-home"
                            role="tab"
                            aria-controls="home"
                            onClick={() => setPdf(pdf1)}
                        >
                            Ogrodzenie panelowe 2D
                        </a>
                        <a
                            className="list-group-item list-group-item-action"
                            id="list-profile-list"
                            data-toggle="list"
                            href="#list-profile"
                            role="tab"
                            aria-controls="profile"
                            onClick={() => setPdf(pdf2)}
                        >
                            Ogrodzenie panelowe 3D
                        </a>
                        <a
                            className="list-group-item list-group-item-action"
                            id="list-messages-list"
                            data-toggle="list"
                            href="#list-messages"
                            role="tab"
                            aria-controls="messages"
                            onClick={() => setPdf(pdf3)}
                        >
                            Podmurowka
                        </a>
                        <a
                            className="list-group-item list-group-item-action"
                            id="list-settings-list"
                            data-toggle="list"
                            href="#list-settings"
                            role="tab"
                            aria-controls="settings"
                            onClick={() => setPdf(pdf4)}
                        >
                            Przesłony panelowe
                        </a>
                    </div>
                    {pdfActive === pdf1 ? (
                        <div>
                            {/* <img className="w-100" src={img1} /> */}
                            <br />
                            Praktyczne, trwałe ogrodzenie wykonane z dwóch
                            prętów poziomych i jednego pionowego. Zakończone
                            jednostronnie drutami o wysokości 30mm. Panele
                            proste można stosować w miejscach użyteczności
                            publicznej, w szczególności na placach zabaw.
                            <br />
                            <br />
                            Standardowe wysokości: 1030, 1230, 1430, 1630, 1830,
                            2030, 2230, 2430 mm.
                            <br />
                            <br />
                            Standardowa długość panela: L 2500mm. Rozstaw osiowy
                            słupków 40x60mm: 2560mm. Rozstaw drutów 50x200mm.
                            Panele proste występują w dwóch wersjach średnicy
                            drutów: 6/5/6 mm lub 8/6/8mm.
                            <br />
                            <br />
                            Standardowa kolorystyka: RAL6005, RAL5010, RAL7016,
                            RAL7030, RAL9005, RAL9016
                            <br />
                            <br />
                            Panele ocynkowane ogniowo lub ocynkowane i malowane
                            proszkowo.
                            <br />
                            <br />
                            <a
                                className="btn btn-secondary"
                                href={pdf1}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Otwórz PDF
                            </a>
                        </div>
                    ) : null}
                    {pdfActive === pdf2 ? (
                        <div>
                            <br />
                            Ogrodzenie przemysłowe panelowe idealne do grodzenia
                            obiektów przemysłowych, użyteczności publicznej,
                            budynków mieszkalnych oraz terenów rekreacyjnych i
                            sportowych. Z paneli ogrodzeniowych można wykonać
                            również kojec dla psa, miejsca na pojemniki na
                            śmieci i tymczasowego ogrodzenia placu budowy.
                            Zakończone obustronnie drutami o wysokości 30mm z
                            możliwością ich obcięcia (safe), które stosuje się w
                            miejscach użyteczności publicznej, w szczególności
                            na placach zabaw, przedszkolach. Dostępne są również
                            panele ozdobne w trzech wersjach: wypukły, wklęsły
                            lub fala.
                            <br />
                            <br />
                            Wysoka trwałość na działanie czynników
                            atmosferycznych oraz odporność na uszkodzenia.
                            <br />
                            <br />
                            Występują w wersji z 2, 3 lub 4 przetłoczeniami
                            wzmacniającymi (opisywane jako 2W, 3W, 4W).
                            <br />
                            <br />
                            Panele ocynkowane ogniowo lub ocynkowane i malowane
                            proszkowo.
                            <br />
                            <br />
                            Standardowa kolorystyka: RAL6005, RAL7016, RAL 7024,
                            RAL7030, RAL8017, RAL9005.
                            <br />
                            <br />
                            <a
                                className="btn btn-secondary"
                                href={pdf2}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Otwórz PDF
                            </a>
                        </div>
                    ) : null}
                    {pdfActive === pdf3 ? (
                        <div>
                            <br />
                            {/* <img alt="img3" className="w-100" src={img3} /> */}
                            <br />
                            Do ogrodzeń panelowych i siatkowych proponujemy 3
                            typy podmurówki prefabrykowanej przystosowanej do
                            rozstawu słupów 2,5-2,58m.
                            <br />
                            <br />
                            Podmurówka typ B montowana na betonowych łącznikach
                            wykonanych z betonu B-15 o wadze 13 kg.
                            <br />
                            <br />
                            Płyta jednostronnie gładka o wymiarach L2460mm i
                            H2380mm oraz w dwóch wysokościach H200mm i H300m.
                            Konstrukcja płyty wykonana jest z betonu zbrojonego
                            B-15 o łącznej wadze 39kg. wysokość 200mm
                            <br />
                            <br />
                            <a
                                className="btn btn-secondary"
                                href={pdf3}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Otwórz PDF
                            </a>
                        </div>
                    ) : null}
                    {pdfActive === pdf4 ? (
                        <div>
                            <br />
                            {/* <img alt="img4" className="w-100" src={img4} /> */}
                            Przesłony Screeno Line firmy Betafence to wysokiej
                            jakości systeme stworzonym specjalnie dla paneli
                            Nylofor 3D, Nylofor 3D Pro oraz Nylofor 3D Light II
                            (o szerokości 2,5m) z tworzywa PVC. Zapewniają
                            prywatność i są łatwe w montażu.
                            <br />
                            <br />
                            Montaż wykonuje się na już wiszących panelach.
                            Pionowe elementy przysłon wsuwa się w pionowe oczka
                            i zabezpiecza od dołu specjalnym uchwytem. Od góry
                            montuje się specjalną maskownicę.
                            <br />
                            <br />
                            Elementy składowe: pistwy pionowe, listwa maskująca
                            górna, listwy poziome, klipsy dolne.
                            <br />
                            <br />
                            Screeno Line występują w kolorach zielonym,
                            antracytowy a także imitujący jasne i ciemne drewno.
                            Przeznaczone są dla paneli o wysokościach: 1030,
                            1230, 1530, 1730, 1930, 2030, 2430mm
                            <br />
                            <br />
                            <a
                                className="btn btn-secondary"
                                href={pdf1}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Otwórz PDF
                            </a>
                        </div>
                    ) : null}
                </div>
                <div className="col-md-8">
                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="list-home"
                            role="tabpanel"
                            aria-labelledby="list-home-list"
                        >
                            <div className="pdf-container">
                                <Document
                                    file={pdf1}
                                    onLoadError={console.error}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page
                                        renderTextLayer={false}
                                        renderAnnotations={false}
                                        className="news-pdf"
                                        pageNumber={pageNumber}
                                    />
                                </Document>
                                <div
                                    className="pdf-page-left"
                                    onClick={pageLeft}
                                >
                                    <i className="fa fa-arrow-circle-left" />
                                </div>
                                <div
                                    className="pdf-page-right"
                                    onClick={pageRight}
                                >
                                    <i className="fa fa-arrow-circle-right" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="list-profile"
                            role="tabpanel"
                            aria-labelledby="list-profile-list"
                        >
                            <div className="pdf-container">
                                <Document
                                    file={pdf2}
                                    onLoadError={console.error}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page
                                        renderTextLayer={false}
                                        renderAnnotations={false}
                                        className="news-pdf"
                                        pageNumber={pageNumber}
                                    />
                                </Document>
                                <div
                                    className="pdf-page-left"
                                    onClick={pageLeft}
                                >
                                    <i className="fa fa-arrow-circle-left" />
                                </div>
                                <div
                                    className="pdf-page-right"
                                    onClick={pageRight}
                                >
                                    <i className="fa fa-arrow-circle-right" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="list-messages"
                            role="tabpanel"
                            aria-labelledby="list-messages-list"
                        >
                            <div className="pdf-container">
                                <Document
                                    file={pdf3}
                                    onLoadError={console.error}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page
                                        renderTextLayer={false}
                                        renderAnnotations={false}
                                        className="news-pdf"
                                        pageNumber={pageNumber}
                                    />
                                </Document>
                                <div
                                    className="pdf-page-left"
                                    onClick={pageLeft}
                                >
                                    <i className="fa fa-arrow-circle-left" />
                                </div>
                                <div
                                    className="pdf-page-right"
                                    onClick={pageRight}
                                >
                                    <i className="fa fa-arrow-circle-right" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="list-settings"
                            role="tabpanel"
                            aria-labelledby="list-settings-list"
                        >
                            <div className="pdf-container">
                                <Document
                                    file={pdf4}
                                    onLoadError={console.error}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page
                                        renderTextLayer={false}
                                        renderAnnotations={false}
                                        className="news-pdf"
                                        pageNumber={pageNumber}
                                    />
                                </Document>
                                <div
                                    className="pdf-page-left"
                                    onClick={pageLeft}
                                >
                                    <i className="fa fa-arrow-circle-left" />
                                </div>
                                <div
                                    className="pdf-page-right"
                                    onClick={pageRight}
                                >
                                    <i className="fa fa-arrow-circle-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
