import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import image2base64 from "image-to-base64";
import ImagesList from "./ImagesList";
import axios from "axios";
import uuid from "uuidv4";
import htmlToImage from "html-to-image";
import "../../assets/styles/Generator.scss";
import Configuration from "./Configuration";
import parser from "html-react-parser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Generator = props => {
  const [fenceData, setFenceData] = useState([]);
  const [fenceData2, setFenceData2] = useState([]);
  const [generatedFence, setGeneratedFence] = useState([]);
  const [offer, setOffer] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [investLocation, setInvestLocation] = useState([]);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [configVisible, setConfigVisible] = useState(false);
  const [image, setImage] = useState();
  const [lamelChange, setLamelChange] = useState(1);
  const [count, setCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfDefinition, setPdfDefinition] = useState({
    pageOrientation: "landscape",
    content: [],
    footer: {
      columns: [
        {
          text:
            "Wizualizacje mają charakter koncepyjny i służą jedynie celom pogladowym. Wszystkie wymiary należy sprawdzić w rzeczywistości",
          alignment: "center"
        }
      ]
    },
    styles: {
      header: {
        fontSize: 11,
        bold: true
      },
      header2: {
        fontSize: 20,
        bold: true
      },
      headerRed: {
        fontSize: 15,
        bold: true,
        color: "red"
      },
      defaultStyle: {
        fontSize: 8,
        bold: false
      }
    }
  });

  useEffect(() => {
    axios
      .get(
        `http://crm.palisada.pl/index.php?api=offer&id=${props.match.params.id}`
      )
      .then(response => {
        const elements = [];
        const offerData = [];
        const clientData = [];
        const investmentLocation = [];
        const elHeightArr = [
          {
            "31": 2,
            "48": 3,
            "65": 4,
            "82": 5,
            "99": 6,
            "116": 7,
            "133": 8,
            "150": 9,
            "167": 10,
            "184": 11,
            "201": 12,
            "218": 13
          }
        ];
        response.data.products.map(element => {
          let lamelCount = "";
          let newLamelSpace = "";
          let newLamelCount = "";
          let key;
          toString(key);
          elHeightArr.map(newEl => {
            for (key in newEl) {
              if (key === element.H / 10) {
                return (lamelCount = newEl[key]);
              }
              if (key !== element.H / 10) {
                newLamelCount = Math.floor(element.H / 10 / 14) - lamelChange;
                let numToSpace = newLamelCount;
                let newLamelNum = element.H / 10 - newLamelCount * 14;
                let newLamelSpaceCount = (numToSpace -= 1);
                newLamelSpace = newLamelNum / newLamelSpaceCount;
              }
            }
            return null;
          });
          if (
            element.element !== "akcesoria" &&
            element.element !== "palec" &&
            element.element !== "slup" &&
            element.element !== "automatyka" &&
            element.element !== "panel" &&
            element.element !== "podmurowka" &&
            element.element !== "usluga" &&
            element.element !== "brama" &&
            element.element !== "furtka" &&
            element.ogrodzenie_typ !== "P"
          ) {
            return elements.push({
              id: element.pid,
              index: element.index,
              name: element.element,
              type: element.ogrodzenie_typ,
              amount: Math.floor(element.ilosc),
              totalPostCount: "2",
              data: {
                fencePostLength: "3",
                fenceHeight: element.H / 10,
                fenceLength: element.L / 10,
                lamelSpace:
                  key === element.H / 10 ? "3" : newLamelSpace.toFixed(3),
                lamelLength: element.L / 10 - 2 * 10,
                lamelHeight: "14",
                totalLamelCount:
                  key === element.H / 10 ? lamelCount : newLamelCount
              }
            });
          }
          if (element.element === "furtka" && element.ogrodzenie_typ !== "P") {
            return elements.push({
              id: element.pid,
              index: element.index,
              name: element.element,
              type: element.ogrodzenie_typ,
              amount: Math.floor(element.ilosc),
              totalPostCount: "2",
              data: {
                fencePostLength: "10",
                fenceHeight: element.H / 10,
                fenceLength: element.L / 10,
                lamelSpace:
                  key === element.H / 10 ? "3" : newLamelSpace.toFixed(3),
                lamelLength: element.L / 10 - 2 * 10,
                lamelHeight: "14",
                totalLamelCount:
                  key === element.H / 10 ? lamelCount : newLamelCount
              }
            });
          }
          if (element.element === "brama" && element.ogrodzenie_typ !== "P") {
            return elements.push({
              id: element.pid,
              index: element.index,
              name: element.element,
              type: element.ogrodzenie_typ,
              amount: Math.floor(element.ilosc),
              totalPostCount: "4",
              data: {
                fencePostLength: "3",
                fenceHeight: element.H / 10,
                fenceLength: element.L / 10,
                lamelSpace: key === element.H / 10 ? "3" : newLamelSpace,
                lamelLength: element.L / 10 / 2 - 2 * 3,
                lamelHeight: "14",
                totalLamelCount:
                  key === element.H / 10 ? lamelCount : newLamelCount
              }
            });
          }
          if (element.element === "slup" && element.ogrodzenie_typ !== "P") {
            let postLength = parseFloat(element.profil) / 10;
            return elements.push({
              id: element.pid,
              name: element.element,
              type: element.ogrodzenie_typ,
              amount: Math.floor(element.ilosc),
              totalPostCount: "1",
              data: {
                fencePostLength: postLength,
                fenceHeight: element.H / 10,
                fenceLength: postLength,
                lamelSpace: "",
                lamelLength: "",
                lamelHeight: "",
                totalLamelCount: ""
              }
            });
          }
          return null;
        });
        setFenceData(elements);
        setFenceData2(elements);
        response.data.offer.map(position => {
          return offerData.push({
            oferta: position.numer,
            data: position.data
          });
        });
        setOffer(offerData);
        response.data.client.map(position => {
          return clientData.push({
            email: position.email,
            name: position.imie,
            surname: position.nazwisko,
            city: position.miasto,
            phone1: position.telefon,
            phone2: position.telefon_1,
            business: position.firma
          });
        });
        setClientData(clientData);
        response.data.localization.map(position => {
          return investmentLocation.push({
            location: position.miasto,
            zipCode: position.kod_pocztowy
          });
        });
        setInvestLocation(investmentLocation);
      });
  }, [lamelChange, props.match.params.id]);

  const lamelChangeHandler = () => {
    lamelChange === 1 ? setLamelChange(0) : setLamelChange(1);
  };

  useEffect(() => {
    generatedFence.length >= 1
      ? setShowRemoveBtn(true)
      : setShowRemoveBtn(false);
  }, [generatedFence.length]);

  useEffect(() => {
    image2base64(logo).then(response => {
      setImage(`data:image/png;base64,${response}`);
    });
  });

  const addFenceHandler = (fenceId, event) => {
    const newDiv = event.currentTarget;

    setGeneratedFence([...generatedFence, newDiv.outerHTML]);

    setFenceData(
      fenceData.map((fence, id) => {
        if (id === fenceId) {
          return { ...fence, amount: fence.amount - 1 };
        }
        return fence;
      })
    );
  };

  const removeSingleFenceHandler = (fenceId, fenceIndex) => {
    const newGeneratedFence = [...generatedFence];
    setGeneratedFence(
      newGeneratedFence.filter((fence, i) => fence.indexOf(fenceId) === -1)
    );
    setFenceData(
      fenceData.map((fence, id) => {
        if (id === Number(fenceIndex)) {
          return { ...fence, amount: fence.amount + 1 };
        }
        return fence;
      })
    );
  };

  const removeFenceHandler = () => {
    setFenceData(fenceData2);
    setGeneratedFence([]);
    document.querySelector(".new-fence-div").innerHTML = "";
  };

  let newFLength = [];

  const saveFenceHandler = () => {
    setIsLoading(true);
    const input = document.querySelectorAll(
      ".generated-fence-container .fence-content"
    );
    const cloneFence = document.querySelector(".new-fence-div");
    cloneFence.style.overflow = "hidden";
    cloneFence.style.height = "0";
    const maxWidth = 1000;
    let width = 0;

    const renderFence = () => {
      fenceMulitPage.forEach(element => {
        const div = document.createElement("div");
        div.style.width = "fit-content";
        div.classList.add("generated-fence-container");

        const generatedFence = document.createElement("div");
        generatedFence.classList.add("generated-fence-ready");

        element.forEach(item => {
          return generatedFence.appendChild(item);
        });
        div.appendChild(generatedFence);
        cloneFence.appendChild(div);
      });
    };

    const fenceMulitPage = [];

    let arr = [];
    input.forEach((element, index) => {
      const elemWidth = Number(element.attributes.width.value);
      width += elemWidth;
      if (width > maxWidth) {
        fenceMulitPage.push(arr);
        arr = [];
        width = 0;
        width += elemWidth;
        arr.push(element.cloneNode(true));
      } else {
        arr.push(element.cloneNode(true));
      }

      if (
        input.length - 1 === index &&
        Math.floor(newFLength.reduce((a, b) => a + b, 0)) > 1000
      ) {
        fenceMulitPage.push(arr);
        renderFence();
      }
    });

    const fencePages = document.querySelectorAll(
      ".fence-container .generated-fence-container"
    );

    const pdfDef = [];
    let newElement = [];

    const oferta = offer.map(position => position.oferta);
    const data = offer.map(position => position.data);
    const currentDate = new Date();
    const twoDigitMonth =
      currentDate.getMonth() + 1 >= 10
        ? currentDate.getMonth() + 1
        : "0" + (currentDate.getMonth() + 1);

    const clientEmail = clientData.map(client => client.email);
    const clientName = clientData.map(client => client.name);
    const clientSurname = clientData.map(client => client.surname);
    const clientCity = clientData.map(client => client.city);
    const clientPhone = clientData.map(client => client.phone1);
    const clientPhone2 = clientData.map(client => client.phone2);
    const clientBusiness = clientData.map(client => client.business);

    const investmentLocation = investLocation.map(
      location => location.location
    );
    const investmentLocationZipCode = investLocation.map(
      zipcode => zipcode.zipCode
    );

    fencePages.forEach(element => {
      newElement.push(element);
    });

    let newCount = 0;

    newElement.map((newEl, index) => {
      return htmlToImage.toPng(newEl).then(dataUrl => {
        let newElementWidth = newEl.offsetWidth;
        let newElementHeight = newEl.offsetHeight;
        const widthChange = () => {
          if (index === 0 && newElementWidth < 800) {
            return newElementWidth;
          }
          if (index === 0 && newElementWidth > 800) {
            return 800;
          }
          if (index >= 1 && newElementWidth < 800) {
            return newElementWidth;
          }
          if (index >= 1 && newElementWidth > 800) {
            return 800;
          }
        };
        return (
          pdfDef.push(
            {
              index: index,
              alignment: "justify",
              columns: [
                [
                  {
                    image: image,
                    width: 120,
                    margin: [0, 0, 0, 20]
                  },

                  { text: "Metal Kłos", style: "header" },
                  {
                    text: "lwiny, ul.Ogrodowa 14, 52-116 Wrocław",
                    style: "defaultStyle"
                  },
                  {
                    text: "NIP: 9121005188, REGON: 930460300",
                    style: "defaultStyle"
                  },
                  {
                    text:
                      "tel.: +48 (71) 311 72 91, 95, 98, fax: +48 (71) 346 49 95",
                    style: "defaultStyle"
                  },
                  {
                    text: "e-mail: info@palisada.pl, www.palisada.pl",
                    style: "defaultStyle"
                  },
                  {
                    text:
                      "Nr. konta: 53 1090 2486 0000 0001 0345 8901 w Banku BZ WBK",
                    style: "defaultStyle"
                  }
                ],
                [
                  {
                    text: oferta,
                    style: "header2"
                  },
                  {
                    text: `z dnia ${data}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 10]
                  },
                  {
                    text: `Wydruk z dnia ${currentDate.getFullYear()}-${twoDigitMonth}-${currentDate.getDate()}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 20]
                  },
                  {
                    text: "Osoba prowadząca",
                    style: "headerRed"
                  },
                  {
                    text: "Szpinko Maciej",
                    style: "header"
                  },
                  {
                    text: "Handlowiec",
                    style: "defaultStyle"
                  },
                  {
                    text: "tel. +48 515 515 707",
                    style: "defaultStyle"
                  },
                  {
                    text: "e-mail: maciej.szpinko@palisada.pl",
                    style: "defaultStyle"
                  }
                ],
                [
                  {
                    text: "Dane kontaktowe klienta",
                    style: "headerRed",
                    margin: [0, 0, 0, 0]
                  },
                  {
                    text: `firma ${clientBusiness}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 0]
                  },
                  {
                    text: `${clientName} ${clientSurname}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 0]
                  },
                  {
                    text: `email: ${clientEmail} tel.${clientPhone} ${clientPhone2}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 0]
                  },
                  {
                    text: clientCity,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 20]
                  },
                  {
                    text: "Lokalizacja inwestycji",
                    style: "headerRed",
                    margin: [0, 0, 0, 0]
                  },
                  {
                    text: `${investmentLocation}${investmentLocationZipCode}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 0]
                  }
                ]
              ]
            },
            {
              index: index,
              image: dataUrl,
              width: widthChange(),
              height: index === 0 ? "" : newElementHeight - 20,
              alignment: "center",
              margin: [0, 50, 0, 0],
              pageBreak: "after"
            }
          ),
          (newCount += 1),
          newCount === newElement.length ? setCount(0) : setCount(1),
          setPdfDefinition({
            ...pdfDefinition,
            content: pdfDef.sort((a, b) => a.index - b.index)
          })
        );
      });
    });
  };

  useEffect(() => {
    if (pdfDefinition.content.length) {
      if (count === 0) {
        const last = pdfDefinition.content.length - 1;
        pdfDefinition.content[last].pageBreak = null;
        const timer = setTimeout(() => {
          pdfMake
            .createPdf(pdfDefinition)
            .download("Score_Details.pdf", () => setIsLoading(false));
          document.querySelector(".new-fence-div").innerHTML = "";
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [pdfDefinition, count]);

  const addConfigurationHandler = () => {
    !configVisible ? setConfigVisible(true) : setConfigVisible(false);
  };

  const offerElements = offer.map((position, i) => {
    return (
      <div key={i}>
        <p style={{ fontSize: "18px", fontWeight: "bold", margin: "20px" }}>
          Oferta {position.oferta} z dnia {position.data}
        </p>
      </div>
    );
  });

  return (
    <div className="generator-container">
      <h1>Generator ogrodzenia</h1>
      {offerElements}
      <ImagesList fenceData={fenceData} addItemHandler={addFenceHandler} />
      <div className="btn-container">
        <button
          className="remove-fence-btn"
          onClick={lamelChangeHandler}
          style={{ width: "200px" }}
        >
          Zageszczenie lameli
        </button>
      </div>
      <div className="generated-fence">
        <div className="fence-container">
          <div className="fence-config">
            <div className="generated-fence-container">
              {generatedFence.map(fence => {
                const id = parser(fence).props.id;
                const index = parser(fence).props.index;
                const fLength = parser(fence).props.width;
                newFLength.push(Number(fLength));
                return (
                  <div className="generated-fence-ready" key={uuid()}>
                    {parser(fence)}
                    <div className="generated-fence-btn">
                      <button
                        className="remove-single-fence-btn"
                        onClick={() => removeSingleFenceHandler(id, index)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="new-fence-div"></div>
          </div>
        </div>
      </div>
      <div style={{ width: "fit-content", margin: "0 auto" }}>
        <p
          style={{
            fontWeight: "bold",
            marginTop: "20px"
          }}
        >
          Długość ogrodzenia:{" "}
          {Math.floor(newFLength.reduce((a, b) => a + b, 0)) * 10}
        </p>
      </div>
      {showRemoveBtn && (
        <>
          <button className="remove-fence-btn" onClick={removeFenceHandler}>
            Usuń ogrodzenie
          </button>
          <button
            type="button"
            className="remove-fence-btn"
            onClick={saveFenceHandler}
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Zapisz ogrodzenie"
            )}
          </button>
        </>
      )}
      <hr />
      <div className="btn-container">
        <button className="remove-fence-btn" onClick={addConfigurationHandler}>
          Dane elementów
        </button>
      </div>
      <div className="configuration-container">
        <div className="configuration-content">
          {configVisible && (
            <Configuration fenceData={fenceData} offer={offer} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Generator;
