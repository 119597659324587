import React from "react";
import Calendar from "../components/Calendar/CalendarView";
import Partners from "../components/Homepage/Partners";
import "../assets/styles/Homepage.scss";
import About from "../components/Homepage/About";

export default function Homepage() {
    return (
        <div className="home-page container">
            <Partners />
            <About />
            <h2 className="my-4">KALENDARZ - SERWIS</h2>
            <Calendar link={"service"} />
            <h2 className="my-4">KALENDARZ - PRODUKCJA</h2>
            <Calendar link={"production"} />
        </div>
    );
}
