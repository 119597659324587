import React, { useState } from "react";
import "../../assets/styles/Configuration.scss";
import handle from "../../assets/images/handle.png";
import htmlToImage from "html-to-image";
import Table from "./Table";
import uuid from "uuidv4";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Configuration = props => {
  const [fenceData] = useState(props.fenceData);
  const [offer] = useState(props.offer);

  return fenceData.map((fence, id) => {
    const post = {
      width: `${fence.data.fencePostLength}px`,
      border: "1px solid black",
      backgroundColor: "#C5D6E0",
      height: `${fence.data.fenceHeight}px`
    };
    const lamel = {
      width: `${fence.data.lamelLength}px`,
      border: "1px solid black",
      backgroundColor: "#C5D6E0",
      height: `${fence.data.lamelHeight}px`
    };
    const lamelSpace = {
      width: `${fence.data.lamelLength}px`,
      backgroundColor: "#FFF",
      height: `${fence.data.lamelSpace}px`
    };

    let newPostList = [];
    let newPostList2 = [];
    let newPostList3 = [];

    for (let i = 0; i < 1; i++) {
      newPostList.push(<div style={post} key={uuid()} />);
      if (`${fence.totalPostCount}` === "2") {
        newPostList3.push(<div style={post} key={uuid()} />);
      }
      if (`${fence.totalPostCount}` === "4") {
        newPostList2.push(<div style={post} key={uuid()} />);
        newPostList3.push(<div style={post} key={uuid()} />);
      }
    }

    let newDivList = [];
    let newDivList2 = [];

    for (let i = 0; i < `${fence.data.totalLamelCount}`; i++) {
      newDivList.push(
        [<div style={lamel} key={uuid()} />],
        [<div style={lamelSpace} key={uuid()} />]
      );

      if (`${fence.totalPostCount}` === "4") {
        newDivList2.push(
          [<div style={lamel} key={uuid()} />],
          [<div style={lamelSpace} key={uuid()} />]
        );
      }
    }

    newDivList.pop();
    newDivList2.pop();

    const saveFenceHandler = id => {
      const input = document.querySelector(`#config-container-${id}`);

      const oferta = offer.map(position => position.oferta);
      const data = offer.map(position => position.data);

      htmlToImage.toPng(input).then(dataUrl => {
        var docDefinition = {
          pageOrientation: "landscape",
          content: [
            {
              alignment: "justify",
              columns: [
                [
                  { text: oferta, style: "header2" },
                  {
                    text: `z dnia ${data}`,
                    style: "defaultStyle",
                    margin: [0, 0, 0, 10]
                  }
                ],
                [
                  { text: "Osoba prowadząca", style: "headerRed" },
                  { text: "Szpinko Maciej", style: "header" },
                  { text: "Handlowiec", style: "defaultStyle" },
                  { text: "tel. +48 515 515 707", style: "defaultStyle" }
                ]
              ]
            },
            {
              image: dataUrl,
              width: 2500,
              alignment: "center",
              margin: [0, 50, 0, 0]
            }
          ],
          styles: {
            header: {
              fontSize: 11,
              bold: true
            },
            header2: {
              fontSize: 20,
              bold: true
            },
            headerRed: {
              fontSize: 15,
              bold: true,
              color: "red"
            },
            defaultStyle: {
              fontSize: 8,
              bold: false
            }
          }
        };
        pdfMake.createPdf(docDefinition).download("Score_Details.pdf");
      });
    };

    return (
      <div className="config-box" key={id}>
        <div
          className="tableComponent"
          ref={props.dataRef}
          id={`config-container-${id}`}
        >
          <Table fence={fence} />
          <div
            className="ready-config-box"
            id={`ready-config-box-${id}`}
            ref={props.imageRef}
          >
            <div>
              {newPostList}
              <img
                alt="handleIconImage"
                className="handleIcon"
                src={handle}
                style={
                  fence.name === "furtka"
                    ? {
                        display: "block"
                      }
                    : { display: "none" }
                }
              ></img>
            </div>
            <div className="bars-config" id={`bars-config-${id}`}>
              {newDivList}
            </div>
            <div>{newPostList2}</div>
            <div>{newPostList2}</div>
            <div className="bars-config" id={`bars-config-${id}`}>
              {newDivList2}
            </div>
            <div>{newPostList3}</div>
          </div>
        </div>
        <div className="btn-container">
          <button
            className="btn-secondary"
            onClick={() => saveFenceHandler(id)}
          >
            Zapisz Przęsło
          </button>
        </div>
      </div>
    );
  });
};

export default Configuration;
